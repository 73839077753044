<template>
  <div class="pl-7 pr-8 pb-2">

    <div v-if="displayEdit" class="pt-4">

      <!-- Video Type Selector  -->
      <v-btn-toggle  @change="changeVideoType" v-model="videoType" mandatory  :color="wsACCENT" background-color="white">
        <v-btn height="40" value="upload" :style="`border-color: ${wsACCENT} !important`" color="white" class="noCaps px-6" >
          <v-icon class="mr-2" text :color="wsACCENT">mdi-upload</v-icon>
          {{  $t('Upload')  }}
        </v-btn>
        <v-btn height="40" value="embed" :style="`border-color: ${wsACCENT} !important`" text class="noCaps px-6" >
          <v-icon class="mr-2" :color="wsACCENT">mdi-link-variant</v-icon>
          {{  $t('EmbedVideo')  }}
        </v-btn>
      </v-btn-toggle>
      <!-- Upload Video Edit  -->


      <ws-video-editor
          v-if="component.video.is_uploaded && videoType !== 'storage'"
          @cancel="displayEdit = false"
          @input="uploadVideo($event)"
          :value="component.video.file"
          :lang="lang"
      />
      <!-- Embed Video Edit  -->
      <ws-embed-editor
          v-if="component.video.is_embed"
          @input="saveEmbed"
          @cancel="displayEdit = false"
          closable
          v-model="embed"
          video />

    </div>

    <div v-else>
      <div v-if="component.video.is_uploaded" >
        <div class="d-flex align-center justify-space-between">
          <h5 class="my-4 d-flex align-center">
            <v-icon class="mr-1"  :color="wsACCENT">mdi-file</v-icon>
            {{ component.video.file.name || $t('EmbedVideo') }}
          </h5>
          <v-btn @click="displayEdit = true" class="noCaps" :color="wsACCENT" text > {{ $t('Change') }}</v-btn>
        </div>
        <wsVideo :key="refresh" :file="component.video.file" />
        <v-btn v-if="component.video.file" @click="openFile" :color="wsACCENT" outlined class="mt-3 mb-1 noCaps">
          {{ $t('Download') }}
          <v-icon class="ml-2">mdi-download</v-icon>
        </v-btn>
      </div>
      <div v-else>
        <div class="d-flex align-center justify-space-between">
          <h5 class="my-4 d-flex align-center" style="text-transform:capitalize;">
            <v-icon class="mr-1"  :color="wsACCENT">mdi-{{ component.video.type }}</v-icon>
            {{  component.video.type }} {{ $t('Video') }}
          </h5>
          <v-btn @click="displayEdit = true" class="noCaps" :color="wsACCENT" text > {{ $t('Change') }}</v-btn>
        </div>
        <wsEmbed
            v-if="component.video.is_embed"
            :src="component.video.url" />
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "courseEditorVideo",
  props : {
    value : {
      type : Object
    },
    lang : {
      type : String,
      default : 'ua',
    }
  },
  data() {
    return {
      displayStorageBrowser : false,
      refresh : 0,
      component : {
        video : {}
      },
      videoType : 'upload',
      displayEdit : true,
      embed : {},

    }
  },
  watch : {
    value() {
      this.checkData()
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),
  },
  methods : {

    ...mapActions('courses', [ 'EDIT_COMPONENT' ]),
    ...mapActions('upload', [ 'GET_PRIVATE_FILE' ]),

    async openFile() {
      if ( !this.component.video ) {
        return
      }
      if ( !this.component.video.file ) {
        return
      }
      if ( !this.component.video.file.uuid ) {
        return
      }
      let result = await this.GET_PRIVATE_FILE(this.component.video.file.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = this.component.video.file.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    cancelEdit() {
      this.notify('CANCELEE')
    },
    editAction(action) {
      if ( action === 'edit' ) {
        this.displayEdit = true
        return;
      }

      this.$emit('edit',action)
    },
    changeVideoType(type) {

      this.component.video.is_uploaded = type === 'upload' || type === 'storage'
      this.component.video.is_embed = type === 'embed'
      if ( type ==='storage' ) {
        this.displayStorageBrowser = true
      }
    },
    async saveEmbed() {

      let entityData = {
        uuid : this.component.uuid,
        lang : this.selectedLang,
        video_is_embed : true,
        video_embed_url : this.embed.url ,
        video_embed_type : this.embed.type ,
      }

      let result = await this.EDIT_COMPONENT(entityData)
      if (!result) {
        return this.notify(this.$t('Error'))
      }
      this.component.name = result.name
      this.component.video = {
        is_embed : true,
        url : this.embed.url,
        type : this.embed.type
      }
      this.component = Object.assign({},this.component)

      this.displayEdit = false
      this.$emit('input', this.component)
    },
    async uploadVideo($event) {

      let entityData = {
        uuid : this.component.uuid,
        lang : this.selectedLang,
        video : $event.uuid,
        poster : $event.poster_file_id || null
      }

      let result = await this.EDIT_COMPONENT(entityData)
      if (!result) {
        return this.notify(this.$t('Error'))
      }

      this.component.name = result.name
      this.component.file = null
      this.component.video = {
        is_uploaded : true,
        file : $event,
      }

      if ( $event.close ) {
        this.displayEdit = false
      }

    this.$emit('input', this.component)
    },

    checkData() {
      if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.component))  ) {
        this.displayEdit = true
        this.changeInput()
      }



      if ( this.component.video.is_uploaded && this.component.video.file.uuid ) {
        //this.displayEdit = false
      }
      if ( this.component.video.is_embed && this.component.video.url ) {
        this.embed.url = this.component.video.url
        //this.displayEdit = false
      }

      if ( this.component.video.is_uploaded && !this.component.video.file.uuid ) {
        if ( this.component.file ) {
          this.component.video = {
            is_uploaded : true,
            is_embed : null,
            type : null,
            url : null,
            file : this.component.file,
          }
          //this.displayEdit = false
        }
      }


    },
    changeInput() {
      this.refresh++
      let buffer = JSON.parse(JSON.stringify(this.value))
      if ( !buffer.video ) {
        buffer.video = {
          is_uploaded : true,
          is_embed : null,
          type : null,
          url : null,
          file : {},
        }
      }
      this.component =  buffer
    }
  },
  mounted() {
    if ( this.value ) {
      this.changeInput()
      this.checkData()
    }
  }
}
</script>

<style scoped>

</style>