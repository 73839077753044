<template>
  <div>

    <ws-text-editor
        v-model="entity.description"
        :html="entity.text_description"
        @input="editTaskContent"
        :key="component.uuid + 'after_questions'"
        :component-id="component.uuid"
    />

    <v-sheet height="40"></v-sheet>

    <div v-for="(tab,i) in navigationSelect" :key="i">
      <div @click="expandAction(i)"
           class="px-5  pointer d-flex align-center justify-space-between"
           style="border-top : 1px solid var(--wsBACKGROUND); height: 50px"
      >
        <h5 class="wsDARKER d-flex align-center">
          <v-icon :color="wsACCENT">mdi-menu-{{ !tab.expanded ? 'right' : 'down' }}</v-icon>
          {{ $t(tab.text) }}
        </h5>

        <div v-if="tab.value === 'questions' && test && test.question_sets && test.question_sets.length > 0"
             class="d-flex align-center"
        >
          <v-menu offset-y :close-on-content-click="false">

            <template v-slot:activator="{ on, attrs }">
              <v-sheet v-on="on" v-bind="attrs" >
                <h5 class="wsACCENT mr-3">Додатково <v-icon>mdi-menu-down</v-icon></h5>
              </v-sheet>
            </template>
            <v-sheet class="py-3">
              <div class="d-flex justify-space-between align-center pb-2 pl-4">
                <h5 class="wsACCENT font-weight-regular d-flex align-center">

                  {{ $t('testing.total_questions.title') }}
                  <ws-tooltip :text="$t('testing.total_questions.tooltip')">
                    <v-icon small class="ml-1" style="margin-bottom : 2px" :color="wsACCENT">mdi-information-outline</v-icon>
                  </ws-tooltip>

                </h5>

                <div class="d-flex">
                  <input
                      @change="editTest( test.max_questions , 'max_questions')"
                      @keydown="handleMaxQuestionsKeyDown($event , test.max_questions)"
                      v-model="test.max_questions" type="number"
                      :placeholder="totalQuestions"
                      class="no-arrows mr-2"
                      style="outline: none;text-align: right"

                  >
                  <v-sheet  width="30" class="d-flex align-center">
                    <v-icon
                        v-if="test.max_questions"
                        class="pointer"
                        @click="test.max_questions = null; editTest( null , 'max_questions')"
                        small :color="wsACCENT">
                      mdi-close
                    </v-icon>
                  </v-sheet>

                </div>

              </div>
              <v-divider style="border-color : var(--wsBACKGROUND)" />
              <div class="d-flex justify-space-between align-center mt-2 pl-4 pr-5">
                <h5 class="wsACCENT font-weight-regular d-flex align-center">

                  {{ $t('testing.shuffle_questions.title') }}


                  <ws-tooltip :text="$t('testing.shuffle_questions.tooltip') ">
                    <v-icon small class="ml-2" :color="wsACCENT">mdi-information-outline</v-icon>
                  </ws-tooltip>

                </h5>
                <ws-check-box
                    @input="editTest( $event , 'shuffle_questions')"
                    v-model="test.shuffle_questions"
                    class="ml-5 " :color="wsACCENT" :small="false" />
              </div>

            </v-sheet>

          </v-menu>

          <h5 @click.stop="displayPreview = true" class="wsATTENTION">{{ $t('Preview') }}</h5>
        </div>
      </div>

      <v-expand-transition>
        <div v-if="tab.expanded"
             style="border-top: 1px solid var(--wsBACKGROUND)"
        >
          <test-questions-table
              v-if="tab.value === 'questions'"
              :test="test"
              :course-id="component.course_id"
              :component="component"
              @update="initPage"
              @drag="handleDrag"
          />
          <test-settings
              v-if="tab.value === 'settings'"
              :test="test"
              :course-id="component.course_id"
          />

        </div>
      </v-expand-transition>


      <test-preview-dialog
          v-if="displayPreview"
          v-model="displayPreview"
          :test-id="test.uuid"
      />


    </div>
  </div>
</template>


<script>
import {mapActions, mapState} from "vuex";
import testQuestionsTable
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/test/testQuestionsTable.vue";
import testSettings from "@/components/pages/westudy/admin/course/coureseEditor/compenents/test/testSettings.vue";
import testPreviewDialog
  from "@/components/pages/westudy/tests/testPreviewDialog.vue";

export default {
  name: "courseEditorTest",
  components : {
    testPreviewDialog,
    testQuestionsTable,
    testSettings
  },
  props : {
    component : {
      type : Object,
      default() { return {} }
    }
  },
  data() {
    return {
      displayPreview : false,
      entity : {},
      navigationSelect : [
          { text : 'ChooseQuestions' , value : 'questions' , expanded : false} ,
          { text : 'Settings' , value : 'settings', expanded : false}
      ],
      test : {
        question_sets : []
      },
      questionBanks : [],
      entityData : {},
    }
  },
  computed : {
    ...mapState('courses', [ 'selectedLang']),

    hasAutoQuestions() {

      let result = false;

      if ( this.test.question_sets && this.test.question_sets.length > 0 ) {

        for ( let item of this.test.question_sets ) {
          if (item.type === 'auto' && item.question_set_groups && item.question_set_groups[1]) {
            result = true
          }
        }

      }

      return result;

    },
    totalQuestions() {
      let count = 0;
      if ( this.test.question_sets && this.test.question_sets.length > 0 ) {
        for ( let item of this.test.question_sets ) {
          if (item.type === 'question') {
            count++;
          }
          if (item.type === 'auto' && item.question_set_groups && item.question_set_groups[1]) {
            count += parseInt(item.question_set_groups[1])
          }
        }
      }
      return count;
    }
  },
  watch : {
    selectedLang() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('courses', [
      'GET_TASK_EDIT',
      'SAVE_TASK',
    ]),
    ...mapActions('tests', [
      'ADD_QUESTION_BANK',
      'CREATE_TEST_QUESTION_SETS',
      'SAVE_TEST',
      'REORDER_TEST',
    ]),

    async editTest(value,field) {
      if ( field === 'passing_score' && value > 100 ) {
        value = 100
        this.test.passing_score = 100
      }
      if (field === 'max_questions') {
        if (value < 1) {
          value = null
        }
        if (value > this.totalQuestions) {
          value = this.totalQuestions
          this.test.max_questions = this.totalQuestions
        }
      }
      if ( field === 'passing_score' && value === ''  ) {
        this.notify('value')
        value = 50
        this.test.passing_score = 50
      }
      if ( field === 'passing_score' && value < 1 ) {
        value = 1
        this.test.passing_score = 1
      }

      let data = {
        [field] : value,
        uuid : this.test.uuid
      }
      let result = await this.SAVE_TEST(data)
      if ( !result ) {
        return this.notify('Error')
      }

    },

    handleMaxQuestionsKeyDown($event , element ) {
        this.NUMBER_INPUT($event,element, 3 , true )

    },
    handleDrag($event) {
      let order =  $event.map(el => el.uuid)
      let data = {
        test : this.test.uuid,
        question_sets : order,
      }
      this.REORDER_TEST(data)

      this.test.question_sets = this.COPY($event)
      this.test = this.COPY(this.test)

    },

    async editTaskContent(content) {
      let entityData = {
        uuid : this.component.task.value,
        lang : this.selectedLang,
        description : content
      }

      let result = await this.SAVE_TASK(entityData)

      if ( !result ) {
        return this.notify('Error')
      }

    },

    expandAction(index) {
      this.navigationSelect[index].expanded = !this.navigationSelect[index].expanded
      this.navigationSelect = [...this.navigationSelect]
    },

    async initPage() {

      if ( !this.component.task ) {
        return
      }
      this.loading = true
      let result = await this.GET_TASK_EDIT( {uuid : this.component.task.value , lang : this.selectedLang } )
      if ( !result ) {
        this.loading = false
        return
      }
      this.loading = false

      this.entity = result.task
      this.test = result.test
      this.questionBanks = result.question_banks
      if ( this.test.question_sets.length > 0 ) {
        this.$emit('testReady')
      }

    },

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>
/* For Chrome, Edge, and Safari */
.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
.no-arrows[type="number"] {
  -moz-appearance: textfield;
}

/* General styling for the input (optional) */
.no-arrows {
  width: 100px;
  height: 30px;
  font-size: 16px;
}
</style>